import React, {useEffect, useState} from "react"
import { Link, navigate } from "gatsby"
import {Container,Navbar,Nav, Row, Col, Form, Button} from 'react-bootstrap';
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 

import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

const PresentationPage = () => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('presentation-success');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    const handleSubmit = () => {
        navigate(config.custom_contact_us)
    }
  
    return <div className="pre-wrapper">
      
      <Header />
    
      <div className="section-wrapper ">
          <Container>
            <h2>{article_data && article_data.Title} </h2>
            <p className="fig-content font_20">{article_data && HTMLReactParser(article_data.Content)}</p>
            
            </Container>
        </div>
      
        <NavLink tab_name="" prevLink={config.custom_fees} nextLink={config.custom_contact_us} />

    </div>
    // pre-wrapper
}

export default PresentationPage
